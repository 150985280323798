import SORT_BY_NAME from './sorters/sort-by-name.js'

class Queries {
  constructor (options) {
    for (const key in options) {
      this[key] = options[key]
    }
  }

  allPeople () {
    return this.state.findAll('people').sort(SORT_BY_NAME)
  }

  findPerson (id) {
    return this.state.findById('people', id)
  }
}

export default Queries
