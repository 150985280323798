import LocalAdapter from './local.js'

class ConfigurationAdapter extends LocalAdapter {
  constructor (name) {
    super('together-progress-v1-configuration')
  }

  _format (configuration) {
    return {
      id: configuration.id,
      endpoint: configuration.endpoint,
      key: configuration.key,
      accessKeyId: configuration.accessKeyId,
      secretAccessKey: configuration.secretAccessKey,
      bucket: configuration.bucket,
      people: configuration.people.map((id) => id)
    }
  }
}

export default ConfigurationAdapter
