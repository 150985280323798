import localForage from 'localforage'

class LocalAdapter {
  constructor (name) {
    name = name || 'touchbase-v1'
    this.db = localForage.createInstance({ name })
  }

  restore () {
    return new Promise((resolve, reject) => {
      this.db.keys().then((keys) => {
        Promise.all(
          keys.map((id) => this.get({ id }))
        )
          .then(resolve)
          .catch(reject)
      }).catch(reject)
    })
  }

  get (personData) {
    return this.db.getItem(this._id(personData.id))
  }

  save (personData) {
    return this.db.setItem(
      this._id(personData.id),
      this._format(personData)
    )
  }

  remove (personData) {
    return this.db.removeItem(this._id(personData.id))
  }

  reset () {
    return this.db.clear()
  }

  _id (id) {
    return `${id}`
  }

  _format (personData) {
    return {
      id: personData.id,
      createdAt: personData.createdAt,
      name: personData.name
    }
  }
}

export default LocalAdapter
