<template>
  <div class="cards">
    <router-link
      v-for="person in app.queries.allPeople()"
      :key="person.id"
      :to="`/people/${person.id}`"
      :aria-label="`See ${person.name}`"
      class="card"
      :style="`border-color: ${person.color}`"
    >
      <img src="/img/logo-bw.svg">
      <h3>{{ person.name }}</h3>
      <h4>Started {{ app.queries.prettyDate(person.createdAt) }}</h4>
    </router-link>

    <router-link
      to="/people/new"
      aria-label="Start a journey"
      class="card centered"
    >
      <img src="/img/logo-bw.svg">
      <h3>Begin a new journey</h3>
      <h4>Start connecting with someone in a purposeful way.</h4>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    app: {
      type: Object,
      default () { return {} }
    }
  }
}
</script>
