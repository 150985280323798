<template>
  <div id="app">
    Welcome!
  </div>
</template>

<script>
import Commands from '@/commands/index.js'
import State from '@/state/index.js'
import Queries from '@/queries/index.js'
import LocalAdapter from '@/adapters/local.js'
import ConfigurationAdapter from '@/adapters/configuration.js'

export default {
  props: {
    state: {
      type: Object,
      default: () => new State({ people: [], configurations: [] })
    },
    localAdapter: {
      type: Object,
      default: () => new LocalAdapter()
    },
    configurationAdapter: {
      type: Object,
      default: () => new ConfigurationAdapter()
    }
  },
  data () {
    window.crm = this

    const queries = new Queries({
      state: this.state,
      localAdapter: this.localAdapter,
      configurationAdapter: this.configurationAdapter
    })
    const commands = new Commands({
      state: this.state,
      queries,
      localAdapter: this.localAdapter,
      configurationAdapter: this.configurationAdapter
    })

    return {
      app: this,
      queries,
      commands
    }
  },
  mounted () {
    if (process.env.NODE_ENV !== 'development') {
      if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
          navigator.serviceWorker.register('/sw.js')
        })
      }
    }

    return this.commands.restoreFromLocal().then(() => {
      this.isLoading = false
    })
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
