class Commands {
  constructor (options) {
    for (const key in options) {
      this[key] = options[key]
    }
  }

  addPerson (person) {
    person.createdAt = person.createdAt || Date.now()
    person = this.state.add('people', [person])[0]
    this.localAdapter.save(person)
  }

  removePerson (person) {
    this.state.removeAll('people', [person])
    this.localAdapter.remove(person)
  }

  savePerson (person) {
    this.localAdapter.save(person)
  }

  restoreFromLocal () {
    return new Promise((resolve, reject) => {
      // this.configurationAdapter.restore()
      //   .then((configurations) => {
      //     this.state.add('configurations', configurations)
      this.localAdapter.restore()
        .then((people) => {
          this.state.add('people', people)
          resolve()
        })
        .catch(reject)
        // })
        // .catch(reject)
    })
  }
}

export default Commands
